.header-container {
  position: relative;
  text-align: center;
  color: #012e55;
  margin-top: -120px;
  margin-bottom: 0;
}

.header-image {
  width: 100%;
  display: block;
}

.header-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: -100px;
}

.header-content h1 {
  font-size: 3.2vw;
  font-family: 'Open Sans', sans-serif;
  padding-left: 150px;
}

@media (max-width: 1224px) {
  .header-content h1 {
    padding-top: 140px;
    padding-left: 50px;
    font-size: 4.5vw;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: auto;
  margin-left: 250px; /* 250 pixels margin to the left */
  margin-right: 250px; /* 250 pixels margin to the right */
  margin-top: 60px;
  margin-bottom: 60px;
}

.container:first-of-type {
  flex-direction: row; /* Image on the left for the first container */
}

.container:nth-of-type(2) {
  flex-direction: row-reverse; /* Image on the right for the second container */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-height: 450px;
  max-width: 100%;
  height: auto;
}

.image-container img:first-child {
  max-height: 600px;
  max-width: 100%;
  height: auto;
}

.text-container {
  flex: 1;
  max-width: 600px;
  margin-left: 20px;
}

@media (max-width: 480px) {
  .text-container {
    max-width: 100%;
  }
}

.text-container h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #012e55;
  font-size: 28px;
}

.text-container h4 {
  color: #012e55;
  margin-bottom: 5px;
}

.text-container p {
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  color: #012e55;
  line-height: 1.6;
  margin-top: 0;
}

.container .image-container,
.container .text-container {
  transform: translateX(-50px);
  opacity: 0;
  transition: all 1.2s ease-out;
}

.container .image-container.slide-in,
.container .text-container.slide-in {
  transform: translateX(0);
  opacity: 1;
}

.button-link {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: #012e55;
  padding: 20px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin-left: 250px;
  cursor: pointer;
  border-radius: 30px;
  transition-duration: 0.4s;
  border: 1px solid #012e55;
}

.button-link:hover {
  background-color: #012e55;
  color: white;
  border: 1px solid white;
}

.text-container .button-link {
  margin-left: -200px;
}

.container:last-of-type {
  margin-left: 550px;
}

@media (max-width: 1224px) {
  .container {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: 0px;
  }

  .container:first-of-type,
  .container:nth-of-type(2) {
    flex-direction: column;
  }

  .container .image-container,
  .container .text-container {
    order: 0; /* Reset the order */
  }

  .container .image-container {
    order: 2; /* Image will be below the text */
    margin-top: 0px; /* Space above the image */
  }

  .container .text-container {
    order: 1; /* Text will be on top */
  }

  .image-container, .text-container {
    margin: 10px 0;
  }

  .text-container .button-link {
    margin-left: 0px;
    font-size: 20px;
    padding: 15px 35px;
  }

  .header-content .button-link {
    margin-left: 50px;
    font-size: 20px;
    padding: 15px 35px;
  }

  .container:last-of-type {
    margin-left: 0px;
  }
}

@media (max-width: 428px) {
  .header-container {
    margin-top: 60px; /* Adjusted to account for navbar height */
  }

  .header-content h1 {
    padding-top: 170px;
    padding-left: 5px;
    font-size: 5.7vw;
  }

  .header-content .button-link {
    margin-left: 5px;
    font-size: 20px;
    padding: 8px 15px;
  }
}

@media (max-width: 393px) {
  .header-container {
    margin-top: 0px;
  }

  .header-content .button-link {
    padding: 8px 12px;
  }
}
