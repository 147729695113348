.header-img {
  max-width: 100%;
  height: auto;
  max-height: 450px;
  width: auto;
}

.image-container-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-container-contact h3 {
  transform: translateY(-50%);
  color: #012e55;
  font-size: 60px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.image-container-contact p {
  transform: translateY(-50%);
  color: #012e55;
  font-size: 30px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  padding: 10px;
  padding-top: 0px;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 30px auto;
  max-width: 600px;
  border: 2px solid #012e55;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.contact-contact {
  display: flex;
  margin-bottom: 15px;
  color: #012e55;
}

.contact-contact span {
  padding-left: 10px;
  font-size: 20px;
}

/* Email Form Container Styles */
.email-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Style for all form inputs and the textarea */
.email-form input,
.email-form textarea {
  width: 90%; /* Responsive width */
  padding: 15px;
  margin-bottom: 15px; /* Space between fields */
  border: 2px solid #012e55; /* Border color to match theme */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size similar to paragraph text */
}

/* Specific style for the textarea to adjust height */
.email-form textarea {
  height: 150px; /* Fixed height for message input */
  resize: vertical; /* Allows vertical resizing */
}

.submit-btn {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.5);
  color: #012e55;
  padding: 15px 35px;
  font-size: 20px;
  border-radius: 30px;
  border: 2px solid #012e55;
  cursor: pointer;
  text-decoration: none; /* Since it's a button, not a link */
  display: inline-block; /* To apply padding and dimensions */
  transition-duration: 0.4s;
  margin-bottom: 30px;
}

.submit-btn:hover {
  background-color: #012e55;
  color: white;
  border: 2px solid white;
}

.success-border {
  border: 2px solid #28a745; /* Green border for success */
}

.error-border {
  border: 2px solid #dc3545; /* Red border for error */
}

.contact-page-container {
  display: flex;
  justify-content: center; /* Center the children horizontally */
  align-items: flex-start; /* Align items to the start of the flex container */
  flex-wrap: wrap; /* Optional: Allow items to wrap on smaller screens */
  margin-left: 200px;
  margin-right: 200px;
}

@media (max-width: 1224px) {
  .contact-page-container {
    flex-direction: column; /* Stack the containers vertically on small screens */
    align-items: center; /* Center align the items */
  }

  .contact-page-container {
    margin-bottom: 30px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 428px) {

  .contact-section {
    max-width: 320px;
  }

  .image-container-contact p {
    text-align: center;
  }

  .email-form-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 393px) {

  .image-container-contact h3 {
    font-size: 50px;
  }

  .image-container-contact p {
    font-size: 25px;
  }
}