.footer {
  background-color: white;
  color: #012e55;
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
}

.footer-top {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 50px;
  border-top: 1px solid #ddd;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 50px;
}

.footer-section img {
  height: 70px;
  margin-bottom: 10px;
}

.footer-section p {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  font-weight: bold;
  margin-top: -10px;
}

.footer-link {
  color: #012e55;
  text-decoration: none;
  margin-bottom: 5px;
}

.footer-section a {
  color: #012e55;
  text-decoration: none;
  margin-bottom: 5px;
}

.footer-section a:hover {
  color: blue;
}

.footer-link:hover {
  color: blue;
}

.footer-contact {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.footer-contact span {
  margin-left: 10px;
}

.footer-bottom {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  width: 100%;
}

.footer-contact-parent {
  padding-top: 25px;
}

@media (max-width: 1224px) {
  .footer-top {
    flex-direction: column;
    align-items: left;
  }
}

@media (max-width: 428px) {
  .footer {
    padding-left: 0px;
    margin-left: 0px;
  }
}