.map-container {
    height: 500px;
    width: calc(100% - 400px); /* 200px left + 200px right */
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 60px;
  }
  
  @media (max-width: 1224px) {
    .map-container {
      width: calc(100% - 60px); /* 30px left + 30px right */
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  