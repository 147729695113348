.header-img {
    max-width: 100%;
    height: auto;
    max-height: 450px;
    width: auto;
  }
  
  .image-container-e {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Added for positioning children absolutely */
  }
  
  .image-container-e h3 {
    position: absolute;
    left: 15%; /* Adjust as needed for center-left alignment */
    top: 35%;  /* Center vertically */
    transform: translateY(-50%); /* Adjust vertical centering */
    color: white; /* Optional: change color if needed */
    font-size: 70px;
  }
  
  .image-container-e p {
    position: absolute;
    left: 15%; /* Adjust as needed for center-left alignment */
    top: 60%;  /* Center vertically */
    transform: translateY(-50%); /* Adjust vertical centering */
    color: white; /* Optional: change color if needed */
    font-size: 30px;
  }

  .about-container-e p {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter;
    color: #012e55; /* Blue color for paragraphs */
    line-height: 1.6; /* Adjust the space between text rows */
    margin-top: 0px;
  }
  
  .about-container-e h3 {
    font-size: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #012e55; /* Blue color for paragraphs */
    line-height: 1.6; /* Adjust the space between text rows */
    margin-top: 0px;
  }

  .about-container-s h4 {
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #012e55; /* Blue color for paragraphs */
    line-height: 1.6; /* Adjust the space between text rows */
    margin-top: 0px;
  }

  .about-container-s img {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .help-how {
    border: 2px solid #012e55;
    border-radius: 20px;
    margin-top: 30px;
    margin-left: 250px;
    margin-right: 250px;
    margin-bottom: 50px;
    padding-top: 40px;
    padding-left: 0;  /* Added to remove padding inside the border */
    padding-right: 0; /* Added to remove padding inside the border */
  }

  .help-how .about-container-e {
    padding-bottom: 40px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 40px;  /* Set to 0 to ensure text aligns with border */
    padding-right: 40px; /* Set to 0 to ensure text aligns with border */
  }

  .flexis {
    display: flex;
    flex-direction: row;
  }

  .flexis h3 {
    padding-top: 40px;
  }

  /* .flexis > div {
    width: 50%;
  } */

  .flexis img {
    position: relative;
    float: right;
    max-width: 600px;
  }

  @media (max-width: 1224px) {
    .help-how {
      margin-left: 20px;
      margin-right: 20px;
    }

    .flexis {
      flex-direction: column;
    }

    .flexis > div {
      width: 100%;
    }

    .flexis h3 {
      margin-right: 0px;
    }

    .flexis img {
      position: relative;
      float: left;
      padding-left: 20px;
    }

    .image-container-e h3 {
      left: 5%; /* Adjust as needed for center-left alignment */
      top: 35%;  /* Center vertically */
      font-size: 60px;
    }
    
    .image-container-e p {
      left: 5%; /* Adjust as needed for center-left alignment */
      top: 75%;  /* Center vertically */
      font-size: 23px;
    }
  }

  @media (max-width: 428px) {

    .image-container-e {
      display: flex;
      flex-direction: column;
      margin-bottom: -40px;
    }

    .about-container-s {
      padding-left: 20px;
    }

    .image-container-e h3 {
      color: #012e55;
      position: static;
      font-size: 50px;
    }

    .image-container-e p {
      color: #012e55;
      position: static;
      font-size: 25px;
      margin-left: 10px;
      margin-top: -35px;
      text-align: center;
    }

    .flexis img {
      margin-left: -40px;
      width: 380px;
    }
  }
  
  @media (max-width: 393px) {

    .flexis img {
      margin-left: -35px;
    }
  }